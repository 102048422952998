import React, { useState, useEffect } from "react";
import { Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import letterSVG from "../assets/image/letter.svg";
import Lock from "../assets/image/lock.svg";
import Swal from "sweetalert2";
import { Card, CardContent, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Footer from "../components/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";

export default function Register() {
  const theme = useTheme();
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [nit, setNit] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [selected, setSelected] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [send, setSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Variables para controlar el tamaño de la imagen
  const isAbove1000px = useMediaQuery("(min-width:1000px)");
  const isBelow833px = useMediaQuery("(max-width:833px)");

  // Variable para controlar el tamaño de la imagen en el media query md
  const mediaMd = useMediaQuery(theme.breakpoints.up("md"));

  const handleRegister = () => {
    setIsLoading(true);

    // Generar tiempo aleatorio entre 2000 ms (2 segundos) y 5000 ms (5 segundos)
    const randomTime = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;

    setTimeout(() => {
      setSend(true);
      setIsLoading(false);
      setNombre("");
      setApellido("");
      setCorreo("");
      setNit("");
      setTelefono("");
      setMensaje("");
      setSelected(null);
    }, randomTime);
  };

  const handleChange = (id) => {
    setSelected(id);
  };

  // Validación del formulario
  const validateForm = () => {
    const isNombreValid = nombre.trim() !== "";
    const isApellidoValid = apellido.trim() !== "";
    const isCorreoValid = /^\S+@\S+\.\S+$/.test(correo);
    const isNitValid = nit.trim() !== "";
    const isTelefonoValid = telefono.trim() !== "";
    const isMensajeValid = mensaje.trim() !== "";
    const isCheckboxSelected = selected !== null;

    // Si todas las condiciones se cumplen, habilitar el botón
    setIsButtonDisabled(
      !(
        isNombreValid &&
        isApellidoValid &&
        isCorreoValid &&
        isNitValid &&
        isTelefonoValid &&
        isMensajeValid &&
        isCheckboxSelected
      )
    );
  };

  // Ejecutar la validación cada vez que cambia un campo del formulario
  useEffect(() => {
    validateForm();
  }, [nombre, apellido, correo, nit, telefono, mensaje, selected]);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Grid
        container
        sx={{
          background: "linear-gradient(180deg, #0153FE 0%, #02E1B0 100%)",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
        px={{ xs: 2, md: 5 }}
        py={10}
        colomns={12}
      >
        <Grid
          size={{ md: 6 }}
          className="zoom-in"
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box mt={10} sx={{ width: { md: 160, lg: 180, xl: 200 } }}>
            <Image
              src={require("../assets/image/logo.png")}
              alt="Logo"
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>

          <Box mt={-10} sx={{ width: { md: 400, lg: 450, xl: 518 } }}>
            <Image
              src={require("../assets/image/revision.png")}
              alt="Logo"
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>

          <Typography component="Box" style={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "white",
                lineHeight: 0.9,
                letterSpacing: -1,
                fontSize: { md: "2.4rem", lg: "2.7rem", xl: "3.1rem" },
              }}
            >
              Comienza con nosotros
            </Typography>
            <Typography
              sx={{
                color: "white",
                lineHeight: 0.9,
                fontSize: { md: "2.8rem", lg: "3.4rem", xl: "3.9rem" },
                letterSpacing: -1,
                fontWeight: "bold",
              }}
              mt={1}
            >
              ¡Hora de registrarte!
            </Typography>
          </Typography>
        </Grid>
        <Grid
          size={{ md: 6 }}
          height={"100%"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.2, duration: 0.7 },
            }}
            viewport={{ once: true }}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              component="Box"
              style={{ textAlign: "center" }}
              sx={{ display: { xs: "block", md: "none" } }}
              pt={10}
            >
              <Typography
                sx={{
                  color: "white",
                  lineHeight: 0.9,
                  letterSpacing: -1,
                  fontSize: { xs: "2rem", sm: "2.4rem" },
                }}
              >
                Comienza con nosotros
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  lineHeight: 0.9,
                  fontSize: { xs: "2.4rem", sm: "2.8rem" },
                  letterSpacing: -1,
                  fontWeight: "bold",
                }}
                mt={1}
              >
                ¡Hora de registrarte!
              </Typography>
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "white",
                borderRadius: 5,
                width: { md: isAbove1000px ? 435 : 369, lg: 500, xl: 530 },
                height: 719,
                padding: "0 42px 36px 42px",
              }}
              mt={10}
              mx={{ sm: 4, md: "initial" }}
            >
              <Box
                sx={{
                  backgroundColor: "#11ddb3",
                  color: "white",
                  borderRadius: 2,
                  width: {
                    xs: 269,
                    sm: 400,
                    md: isAbove1000px ? 319 : 250,
                    lg: 350,
                    xl: 400,
                  },
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                  marginTop: "-39px",
                }}
              >
                <Typography
                  sx={{
                    margin: 0,
                    fontWeight: "bold",
                    fontSize: { xs: "1.8rem", sm: "2rem", xl: "2.5rem" },
                    letterSpacing: -1,
                  }}
                >
                  Regístrate
                </Typography>
              </Box>
              {send ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: "100%",
                  }}
                  className="zoom-in"
                >
                  <Box>
                    <svg
                      style={{
                        width: 105,
                        height: 105,
                        fill: "#1555fd",
                        margin: "65px auto 20px auto",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480l0-83.6c0-4 1.5-7.8 4.2-10.8L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
                    </svg>
                    <Typography component="Box" style={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          color: "#006BF1",
                          lineHeight: 0.9,
                          fontWeight: "bold",
                          letterSpacing: -1,
                          fontSize: {
                            xs: "1.5rem",
                            lg: "1.6rem",
                            xl: "2rem",
                          },
                        }}
                      >
                        ¡Listo!
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "#797979",
                          fontSize: 22,
                          letterSpacing: -1,
                        }}
                        mt={0.5}
                      >
                        Tus datos han sido <b>enviados</b> con éxito
                      </Typography>
                    </Typography>
                  </Box>

                  <Typography component="Box" style={{ textAlign: "center" }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#797979",
                        fontSize: 22,
                        letterSpacing: -1,
                      }}
                      mt={0.5}
                    >
                      Nuestras asesoras pronto se pondrán
                    </Typography>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#797979",
                        fontSize: 22,
                        letterSpacing: -1,
                      }}
                      mt={-1.5}
                    >
                      <b>en contacto contigo</b>
                    </Typography>
                  </Typography>
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#1555fd",
                      color: "white",
                      borderRadius: 8,
                      fontWeight: "bold",
                      width: isBelow833px ? 150 : 230,
                      marginTop: 20,
                      padding: "12px 0",
                      fontSize: 20,
                    }}
                    onClick={() => setSend(false)}
                  >
                    Finalizar
                  </button>
                </Box>
              ) : (
                <Box>
                  <Row className="mt-4 ">
                    <Col>
                      <div className="wave-group">
                        <input
                          required
                          type="text"
                          className={`input ${nombre ? "valid" : ""}`}
                          name="nombre"
                          value={nombre}
                          onChange={(e) => setNombre(e.target.value)}
                          disabled={isLoading}
                        />
                        <span className="bar"></span>
                        <label className="label">
                          <span className="label-char" style={{ "--index": 0 }}>
                            N
                          </span>
                          <span className="label-char" style={{ "--index": 1 }}>
                            o
                          </span>
                          <span className="label-char" style={{ "--index": 2 }}>
                            m
                          </span>
                          <span className="label-char" style={{ "--index": 3 }}>
                            b
                          </span>

                          <span className="label-char" style={{ "--index": 4 }}>
                            r
                          </span>

                          <span className="label-char" style={{ "--index": 5 }}>
                            e
                          </span>

                          <span className="label-char" style={{ "--index": 6 }}>
                            s
                          </span>
                        </label>
                      </div>
                    </Col>
                    <Col>
                      <div className="wave-group">
                        <input
                          required
                          type="text"
                          className="input"
                          name="apellidos"
                          value={apellido}
                          onChange={(e) => setApellido(e.target.value)}
                          disabled={isLoading}
                        />
                        <span className="bar"></span>
                        <label className="label">
                          <span className="label-char" style={{ "--index": 0 }}>
                            A
                          </span>
                          <span className="label-char" style={{ "--index": 1 }}>
                            p
                          </span>
                          <span className="label-char" style={{ "--index": 2 }}>
                            e
                          </span>
                          <span className="label-char" style={{ "--index": 3 }}>
                            l
                          </span>
                          <span className="label-char" style={{ "--index": 4 }}>
                            l
                          </span>
                          <span className="label-char" style={{ "--index": 5 }}>
                            i
                          </span>
                          <span className="label-char" style={{ "--index": 6 }}>
                            d
                          </span>
                          <span className="label-char" style={{ "--index": 7 }}>
                            o
                          </span>
                          <span className="label-char" style={{ "--index": 8 }}>
                            s
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="wave-group">
                        <input
                          required
                          type="text"
                          className="input"
                          name="correo"
                          value={correo}
                          onChange={(e) => setCorreo(e.target.value)}
                          disabled={isLoading}
                        />
                        <span className="bar"></span>
                        <label className="label">
                          <span className="label-char" style={{ "--index": 0 }}>
                            C
                          </span>
                          <span className="label-char" style={{ "--index": 1 }}>
                            o
                          </span>
                          <span className="label-char" style={{ "--index": 2 }}>
                            r
                          </span>
                          <span className="label-char" style={{ "--index": 3 }}>
                            r
                          </span>
                          <span className="label-char" style={{ "--index": 4 }}>
                            e
                          </span>
                          <span className="label-char" style={{ "--index": 5 }}>
                            o
                          </span>
                          <span className="label-char" style={{ "--index": 6 }}>
                            &nbsp;
                          </span>
                          <span className="label-char" style={{ "--index": 7 }}>
                            e
                          </span>
                          <span className="label-char" style={{ "--index": 8 }}>
                            l
                          </span>
                          <span className="label-char" style={{ "--index": 9 }}>
                            e
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 10 }}
                          >
                            c
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 11 }}
                          >
                            t
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 12 }}
                          >
                            r
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 13 }}
                          >
                            ó
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 14 }}
                          >
                            n
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 15 }}
                          >
                            i
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 16 }}
                          >
                            c
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 17 }}
                          >
                            o
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="wave-group">
                        <input
                          required
                          type="text"
                          className="input"
                          name="nit"
                          value={nit}
                          onChange={(e) => setNit(e.target.value)}
                          disabled={isLoading}
                        />
                        <span className="bar"></span>
                        <label className="label">
                          <span className="label-char" style={{ "--index": 0 }}>
                            N
                          </span>
                          <span className="label-char" style={{ "--index": 1 }}>
                            I
                          </span>
                          <span className="label-char" style={{ "--index": 2 }}>
                            T
                          </span>
                          <span className="label-char" style={{ "--index": 3 }}>
                            &nbsp;
                          </span>
                          <span className="label-char" style={{ "--index": 4 }}>
                            d
                          </span>
                          <span className="label-char" style={{ "--index": 5 }}>
                            e
                          </span>
                          <span className="label-char" style={{ "--index": 6 }}>
                            &nbsp;
                          </span>
                          <span className="label-char" style={{ "--index": 7 }}>
                            l
                          </span>
                          <span className="label-char" style={{ "--index": 8 }}>
                            a
                          </span>
                          <span className="label-char" style={{ "--index": 9 }}>
                            &nbsp;
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 10 }}
                          >
                            e
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 11 }}
                          >
                            m
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 12 }}
                          >
                            p
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 13 }}
                          >
                            r
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 14 }}
                          >
                            e
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 15 }}
                          >
                            s
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 16 }}
                          >
                            a
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="wave-group">
                        <input
                          required
                          type="text"
                          className="input"
                          name="telefono"
                          value={telefono}
                          onChange={(e) => setTelefono(e.target.value)}
                          disabled={isLoading}
                        />
                        <span className="bar"></span>
                        <label className="label">
                          <span className="label-char" style={{ "--index": 0 }}>
                            N
                          </span>
                          <span className="label-char" style={{ "--index": 1 }}>
                            ú
                          </span>
                          <span className="label-char" style={{ "--index": 2 }}>
                            m
                          </span>
                          <span className="label-char" style={{ "--index": 3 }}>
                            e
                          </span>
                          <span className="label-char" style={{ "--index": 4 }}>
                            r
                          </span>
                          <span className="label-char" style={{ "--index": 5 }}>
                            o
                          </span>
                          <span className="label-char" style={{ "--index": 6 }}>
                            &nbsp;
                          </span>
                          <span className="label-char" style={{ "--index": 7 }}>
                            d
                          </span>
                          <span className="label-char" style={{ "--index": 8 }}>
                            e
                          </span>
                          <span className="label-char" style={{ "--index": 9 }}>
                            &nbsp;
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 10 }}
                          >
                            t
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 11 }}
                          >
                            e
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 12 }}
                          >
                            l
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 13 }}
                          >
                            é
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 14 }}
                          >
                            f
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 15 }}
                          >
                            o
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 16 }}
                          >
                            n
                          </span>
                          <span
                            className="label-char"
                            style={{ "--index": 17 }}
                          >
                            o
                          </span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <h6 style={{ marginTop: 20, textAlign: "left" }}>
                        Estilo preferido de paquetes
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        margin: "20px 0",
                      }}
                    >
                      <div className="checkbox-wrapper-46">
                        <input
                          type="checkbox"
                          id="cbx-46-1"
                          className="inp-cbx"
                          checked={selected === "cbx-46-1"}
                          onChange={() => handleChange("cbx-46-1")}
                          disabled={isLoading}
                        />
                        <label htmlFor="cbx-46-1" className="cbx">
                          <span>
                            <svg viewBox="0 0 12 10" height="10px" width="12px">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                          </span>
                          <span>Sencillo</span>
                        </label>
                      </div>

                      <div className="checkbox-wrapper-46">
                        <input
                          type="checkbox"
                          id="cbx-46-2"
                          className="inp-cbx"
                          checked={selected === "cbx-46-2"}
                          onChange={() => handleChange("cbx-46-2")}
                          disabled={isLoading}
                        />
                        <label htmlFor="cbx-46-2" className="cbx">
                          <span>
                            <svg viewBox="0 0 12 10" height="10px" width="12px">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                          </span>
                          <span>Mediano</span>
                        </label>
                      </div>

                      <div className="checkbox-wrapper-46">
                        <input
                          type="checkbox"
                          id="cbx-46-3"
                          className="inp-cbx"
                          checked={selected === "cbx-46-3"}
                          onChange={() => handleChange("cbx-46-3")}
                          disabled={isLoading}
                        />
                        <label htmlFor="cbx-46-3" className="cbx">
                          <span>
                            <svg viewBox="0 0 12 10" height="10px" width="12px">
                              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </svg>
                          </span>
                          <span>Grande</span>
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <h6 style={{ marginTop: 20, textAlign: "left" }}>
                        Cuéntanos sobre tu empresa o el servicio que deseas
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={3}
                          style={{ resize: "none" }}
                          placeholder="Escribe aquí"
                          value={mensaje}
                          onChange={(e) => setMensaje(e.target.value)}
                          disabled={isLoading}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#1555fd",
                      color: "white",
                      borderRadius: 8,
                      fontWeight: "bold",
                      width: isBelow833px ? 150 : 230,
                      marginTop: 20,
                      padding: "12px 0",
                      fontSize: 20,
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "auto",
                      justifyContent: "center",
                    }}
                    onClick={() => handleRegister()}
                    disabled={isLoading || isButtonDisabled}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{ width: 25, height: 25 }}
                        color="inherit"
                      />
                    ) : (
                      "Enviar"
                    )}
                  </button>
                </Box>
              )}
            </Box>
          </motion.div>
        </Grid>
      </Grid>
    </Box>
  );
}
